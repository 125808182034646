<template>
  <div class="layout-blank">
    <slot />
  </div>
</template>

<style scoped>
  .layout-blank {
    width: 100vw;
  }
</style>
